<template>
  <div id="app">
    <h2 class="top-link"><a href="//stone.tsachina.com" target="_self">查看更多产品(View More Products)</a></h2>
    <Layout>
        <Header class="header">
          <div class="header-box">
            <img src="./assets/logo.png" alt="天悦工艺石材有限公司">
            <!-- <p>
              <Icon type="ios-call" size="30"/>
              <span>0086-592-5066868</span>
            </p> -->
          </div>
        </Header>
        <Content class="main">
          <div class="contact">
            <p></p>
            <p><Icon type="ios-chatbubbles" size="20"/><a href="http://wpa.qq.com/msgrd?v=3&uin=248487363&site=qq&menu=yes" target="_blank">天悦石材销售1</a></p>
            <p><Icon type="ios-chatbubbles" size="20"/><a href="http://wpa.qq.com/msgrd?v=3&uin=25727824&site=qq&menu=yes" target="_blank">天悦石材销售2</a></p>
            <p><Icon type="ios-chatbubbles" size="20"/><a href="http://wpa.qq.com/msgrd?v=3&uin=248487363&site=qq&menu=yes" target="_blank">天悦石材销售3</a></p>
            <p><Icon type="md-mail" size="20"/><a href="Mailto:sale@tsachina.com" target="_blank">sale@tsachina.com</a></p>
            <p><Icon type="ios-call" size="20"/><a href="javascript: void(0)">0086-592-5066868</a></p>
            <p></p>
          </div>
          <div class="banner">
            <Carousel autoplay loop :height="370">
              <CarouselItem>
                <div class="carousel-item">
                  <img src="./assets/1.png" alt="">
                </div>
              </CarouselItem>
              <CarouselItem>
                <div class="carousel-item">
                  <img src="./assets/2.png" alt="">
                </div>
              </CarouselItem>
              <CarouselItem>
                <div class="carousel-item">
                  <img src="./assets/3.png" alt="">
                </div>
              </CarouselItem>
              <CarouselItem>
                <div class="carousel-item">
                  <img src="./assets/4.png" alt="">
                </div>
              </CarouselItem>
              <CarouselItem>
                <div class="carousel-item">
                  <img src="./assets/5.png" alt="">
                </div>
              </CarouselItem>
              <CarouselItem>
                <div class="carousel-item">
                  <img src="./assets/6.png" alt="">
                </div>
              </CarouselItem>
          </Carousel>
          </div>
          <div class="main-list">
            <div class="card" v-for="(item, index) in list" :key="index">
              <img :src="item.url" :alt="item.name">
              <p class="item-name">{{item.name}}</p>
            </div>
          </div>
        </Content>
        <Footer class="footer">
          <div>
            <p class="company-name">天悦工艺石材有限公司</p>
            <p class="company-address">福建省厦门市思明区湖滨北路59号19B</p>
            <p class="footer-info">Copyright © 2023 天悦工艺石材有限公司 | <a href="https://beian.miit.gov.cn/" target="_blank">闽ICP备2023003423号-1</a></p>
          </div>
        </Footer>
    </Layout>
    <BackTop></BackTop>
  </div>
</template>

<script>
import jpeg1 from './assets/1-1.jpeg';
import jpeg2 from './assets/1-2.jpeg';
import jpeg3 from './assets/1-3.jpeg';
import jpeg4 from './assets/1-4.jpeg';
import jpeg5 from './assets/1-5.jpeg';
import jpeg6 from './assets/1-6.jpeg';
import jpeg7 from './assets/1-7.jpeg';
import jpeg8 from './assets/1-8.jpeg';
export default {
  name: 'App',
  components: {
  },
  data () {
    return {
      list: [
        { name: '长椅', url: jpeg1 },
        { name: '台阶', url: jpeg2 },
        { name: '长椅', url: jpeg3 },
        { name: '灯笼', url: jpeg4 },
        { name: '围栏', url: jpeg5 },
        { name: '灯笼', url: jpeg6 },
        { name: '喷泉池', url: jpeg7 },
        { name: '塔', url: jpeg8 }
      ]
    }
  }
}
</script>

<style lang="less" scoped>
.top-link{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  height: 36px;
  line-height: 36px;
  background-color: rgb(248, 248, 248);
  text-align: center;
  border-bottom: #ccc;
  & > a{
    color: #5094d5;
    font-size: 16px;
  }
  & > a:hover{
    opacity: 0.9;
  }
}
.header{
  height: 100px;
  margin-top: 36px;
  padding: 0 10px;
  background: url('http://www.tsachina.com/Public/Home/images/top_bg.png');
  .header-box{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    & > img{
      width: 412px;
      height: 49px;
    }
    & > p{
      display: flex;
      align-items: center;
      height: 50px;
      span{
        padding-left: 6px;
        font-size: 18px;
        // color: #fff;
      }
    }
  }
}
.main{
  min-height: calc(~'100vh - 100px - 124px');
  background: url('http://www.tsachina.com/Public/Home/images/top_bg.png');
  .contact{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    padding: 0 10px;
    background-color: #fff;
    & > p{
      color: #515A6E;
      &:hover{
        a{
          color: #3E76F6;
        }
      }
      & > a{
        color: #515A6E;
      }
      i{
        margin-right: 4px;
      }
    }
  }
  .banner{
    .carousel-item{
      width: 100%;
    }
  }
  .main-list{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    // justify-content: flex-start;
    padding: 20px 10px;
    .card{
      display: flex;
      flex-direction: column;
      width: 272px;
      margin-bottom: 10px;
      margin-right: 10px;
      border: solid 1px #ccc;
      border-radius: 6px;
      cursor: pointer;
      &:hover{
        box-shadow: 0 2px 7px rgba(0,0,0,.15);
        border-color: transparent;
      }
      img{
        width: 272px;
        height: 204px;
      }
      .item-name{
        padding: 10px;
        text-align: center;
      }
    }
  }
}
.footer{
  background-color: #212121;
  text-align: center;
  p{
    text-align: center;
  }
  .company-name{
    color: #fff;
  }
  .company-address{
    padding: 10px 0 6px 0;
    color: hsla(0,0%,100%,.4);
  }
  .footer-info{
    color: hsla(0,0%,100%,.4);
    font-size: 12px;
    a{
      color: hsla(0,0%,100%,.4);
    }
  }
}
</style>
